<div id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h4>Our News</h4>
            <h2>Latest Blog <span>Posts</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="blog-slides">
            <owl-carousel-o [options]="blogSlides">
                <ng-template carouselSlide>
                    <div class="single-blog-item bg1">
                        <span>Business</span>
                        <h3><a routerLink="/blog-details">The best gear for starting a small business</a></h3>
                        <p> Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg2">
                        <span>Tech</span>
                        <h3><a routerLink="/blog-details">Get your first business suit right with these tips</a></h3>
                        <p> Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg3">
                        <span>Design</span>
                        <h3><a routerLink="/blog-details">How to Make a Font - Font Design Full Process</a></h3>
                        <p> Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg4">
                        <span>Marketing & Trend</span>
                        <h3><a routerLink="/blog-details">10 Hot Marketing Trends You Need to Implement</a></h3>
                        <p> Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg5">
                        <span>Game</span>
                        <h3><a routerLink="/blog-details">How to Be a Work From Home Professional</a></h3>
                        <p> Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>
                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>