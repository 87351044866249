<section class="how-work-area pt-100 pb-70" id="how we are">
    <div class="container">
        <div class="section-title">
            <h2>Nos <span>Engagements</span></h2>
            <p></p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-cogs"></i>
                    </div>
                    <h3>Accompagnement en méthode agile </h3>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class=" fas fa-user-clock"></i>
                    </div>
                    <h3>Disponibilité et réactivité de nos équipes </h3>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-smile-beam"></i>
                    </div>
                    <h3>Satisfaction de nos clients</h3>
                    <p></p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>