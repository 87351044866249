<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <a class="navbar-brand" routerLink="/"> HK Engineering & consulting </a>
        
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>

            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Accueil </span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about')">À propos</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('how we are')">Engagements</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('services')">Compétences</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('partner')">Technologie</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Contact</span></li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Pricing</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('blog')">Blog</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Contact</span></li> -->
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Accueil </span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about')">À propos</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('how we are')">Engagements</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('services')">Compétences</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('partner')">Technologie</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Contact</span></li>
            </ul>
        </div>
    </div>
</nav>