<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4>Rester en contact </h4>
            <h2><span>Contactez</span> Nous</h2>
            <p>Comment pouvons-nous vous aider ?</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Nom</label>
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Name est obligatoire.</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Email</label>
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Adresse mail est obligatoire.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Sujet</label>
                                    <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject">
                                    <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Sujet est obligatoire.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Numéro de télephone</label>
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Numéro de télephone est obligatoire.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Message</label>
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message est obligatoire.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">Envoyer un message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">                
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="fas fa-map-marker-alt"></i> <span>Location:</span>24 rue Isaac Newton <br> 77 100 Meaux</li>
                                <li><i class="far fa-envelope"></i> <a href="infos@groupehkec.com"><span>Email:</span>infos@groupehkec.com</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:+33 (6) 35 21 40 97"><span>Call:</span>+33 (6) 35 21 40 97</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>