<div class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nos <span> Atouts </span></h2>
            <p></p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>Accompagnement</h3>
                    <p>Un accompagnement en méthode agile de bout en bout, une implication forte de nos équipes dans  l'organisation de vos projets au sein de votre écosystème
                    </p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fab fa-dev "></i>
                    <h3>Développement</h3>
                    <p>Nous développons pour vous en nous appuyant sur les technologies et les solutions les mieux adaptées à votre contexte d’entreprise</p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-lightbulb"></i>
                    <h3>Expertise</h3>
                    <p>Une expertise métiers de nos consultants partageant leurs retours d'expériences et facilitant le transfert de compétences</p>
                    <span>3</span>
                </div>
            </div>
          
        </div>
    </div>
</div>