<div id= "partner" class="partner-area ptb-70 bg-fffdfd">
    <div class="container">
        <div class="partner-slides">
            <owl-carousel-o [options]="partnerSlides">
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a target="_blank"><img src="assets/img/Angular.png" alt="partner" title="Angular"></a>
                       
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a target="_blank"><img src="assets/img/Vue.png" alt="partner" title="Vue JS"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a target="_blank"><img src="assets/img/Python.png" title="Python" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a target="_blank"><img src="assets/img/Kafka.png" title="Kafka" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a target="_blank"><img src="assets/img/Java.png" title="Java" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a target="_blank"><img src="assets/img/MongoDB.png" title="MongoDB" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a target="_blank"><img src="assets/img/Flutter.png" title="Flutter" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a target="_blank"><img src="assets/img/GitHub.png" title="GitHub" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a target="_blank"><img src="assets/img/JavaScript.png" title="JavaScript" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a target="_blank"><img src="assets/img/GoogleCloud.png" title="Google Cloud Platform" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a target="_blank"><img src="assets/img/AmazonWebServices.png" title="Amazon Web Services" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a target="_blank"><img src="assets/img/Scrum.png" title="Scrum" alt="partner"></a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>