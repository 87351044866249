<footer class="footer-area">
    <div class="container">
        <!-- <h3><a routerLink="/"><span>X</span>avro</a></h3> -->
        <!-- <ul>
            <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
            <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
            <li><a href="#" target="_blank" class="fab fa-skype"></a></li>
        </ul> -->
        <p>Copyright <i class="far fa-copyright"></i>2021 All Rights Reserved.</p>
    </div>
</footer>

<!-- <app-demo-sidebar></app-demo-sidebar> -->

<!-- <ngx-scrolltop></ngx-scrolltop> -->