<div class="why-we-different ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Why We <span>Are Different</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="tabset why-we-different-tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab1" aria-controls="speed-flexibility" checked>
            <label for="tab1">Speed , flexibility</label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="profeesional-work">
            <label for="tab2">Profeesional work</label>
            <!-- Tab 3 -->
            <input type="radio" name="tabset" id="tab3" aria-controls="experienced-staff">
            <label for="tab3">Experienced Staff</label>
            <!-- Tab 4 -->
            <input type="radio" name="tabset" id="tab4" aria-controls="custom-support">
            <label for="tab4">Custom Support</label>
            <div class="tab-panels">
                <div id="speed-flexibility" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img2.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Speed , flexibility</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Creative Design</li>
                                    <li><i class="fas fa-check"></i> Retina Ready</li>
                                    <li><i class="fas fa-check"></i> Responsive Design</li>
                                    <li><i class="fas fa-check"></i> Modern Design</li>
                                    <li><i class="fas fa-check"></i> Awesome Design</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="profeesional-work" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img1.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Profeesional work</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Creative Design</li>
                                    <li><i class="fas fa-check"></i> Retina Ready</li>
                                    <li><i class="fas fa-check"></i> Responsive Design</li>
                                    <li><i class="fas fa-check"></i> Modern Design</li>
                                    <li><i class="fas fa-check"></i> Awesome Design</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="experienced-staff" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img3.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Experienced staff</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Creative Design</li>
                                    <li><i class="fas fa-check"></i> Retina Ready</li>
                                    <li><i class="fas fa-check"></i> Responsive Design</li>
                                    <li><i class="fas fa-check"></i> Modern Design</li>
                                    <li><i class="fas fa-check"></i> Awesome Design</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="custom-support" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img4.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>24/7 support</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Creative Design</li>
                                    <li><i class="fas fa-check"></i> Retina Ready</li>
                                    <li><i class="fas fa-check"></i> Responsive Design</li>
                                    <li><i class="fas fa-check"></i> Modern Design</li>
                                    <li><i class="fas fa-check"></i> Awesome Design</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>