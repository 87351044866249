<section id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nos <span>Compétences métiers </span></h2>
            <p>Notre équipe de consultants travaillent à distance, et s'intègrent directement à vos équipes.
                 Ils vous épaulent sur tous les métiers du développement logiciel</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-laptop-code"></i>
                    </div>
                    <h3>Programmation</h3>
                    <p> Nos équipes  dotées d'une maitrise des nouvelles technologies 
                    sont disponibles afin de vous offrir des solutions respectant les
                    bonnes pratiques de la programmation
                    Nous Maîtrisons les langages dev programmation :
                    
                    .NET, PHP, SQL, Java, C#, C, Python, Ruby on Rails etc ...
                     </p>            
                     </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-code"></i>
                    </div>
                    <h3>Développement Web</h3>
                    <p>Nos équipes sont à votre disposition pour vous offrir des solutions web adaptés à vos besoins en utilisant les nouvelles technologies</p>
                    <br>
                    <br>
                    <br>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-ethernet"></i>
                    </div>
                    <h3> IT/Réseaux </h3>
                    <p>Nous vous accompagnerons à mettre en œuvre des solutions réseaux sécurisés afin de satisfaire vos besoins</p>
                    <br>
                    <br>
                    <br>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-chart-line"></i>
                    </div>
                    <h3>E-Marketing </h3>
                    <p>Nos équipes qualifiées sont  à votre disposition pour  vous accompagner afin d'augmenter votre visibilité en ligne et d'avoir une meilleure stratégie de communication digitale</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-user-tie"></i>
                    </div>
                    <h3>Consulting IT </h3>
                    <p>Nos équipes sont à votre disposition afin de vous orienter et aider sur tous vos problématiques et de vous donner des conseils suite à une étude de marché technologique</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-users"></i>
                    </div>
                    <h3> TMA </h3>
                    <p>Nos consultants sont à votre disposition afin de vos offrir du support pour la maintenance appliquée à vos logiciels</p>
               <br>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-mobile-alt"></i>
                    </div>
                    <h3>Responsive Design</h3>
                    <p></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-camera"></i>
                    </div>
                    <h3>Photography</h3>
                    <p></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-heartbeat"></i>
                    </div>
                    <h3>Custom Support</h3>
                    <p></p>
                </div>
            </div> -->
        </div>
    </div>
</section>