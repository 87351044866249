<div class="main-banner item-bg-three">
    <particles [params]="myParams" [style]="myStyle" [width]="width" [height]="height"></particles>
    <div class="creative-banner-two"></div>
    
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <img src="assets/img/HK.png" class="imgMain"> 
                    <h4></h4>
                    <h1> <span>Au service de votre développement </span> </h1>

                    <!-- <a (click)="onClick('about')" class="btn btn-primary">Get Started</a>
                    <a (click)="onClick('work')" class="btn btn-primary view-work">View Work</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-welcome></app-welcome> -->

<app-about></app-about>

<!-- <app-strategy></app-strategy>-->


<!-- 
<app-cta></app-cta>

<app-skill></app-skill> -->
<!-- 
<app-funfacts></app-funfacts> -->

<!-- <app-team></app-team> -->

<app-how-we-work></app-how-we-work>

<app-services-two></app-services-two>

<app-who-we-are></app-who-we-are> 

<!-- <app-why-we-different></app-why-we-different> -->

<!-- <app-work></app-work> -->
<!-- 
<app-pricing></app-pricing>

<app-faq></app-faq>

<app-feedback></app-feedback>

<app-blog></app-blog> -->

<app-partner></app-partner>

<!-- <app-subscribe></app-subscribe> -->

<app-contact></app-contact>